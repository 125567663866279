import {createApp} from 'vue';
import App from './App.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import router from "./router";
import store from "./state/store";
import i18n from './i18n';
import ElementPlus from 'element-plus'
import { plugin as SlickSort } from 'vue-slicksort';


import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import {vMaska} from "maska"

import '@/assets/scss/config/galaxy/app.scss';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import 'element-plus/dist/index.css'

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(ElementPlus)
    .use(BootstrapVueNext)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(SlickSort)
    .directive("maska", vMaska)
    .mount('#app');