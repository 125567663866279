export const state = {
    statements: [],
};

export const mutations = {
    CHANGE_STMT(state, stmts) {
        state.statements = stmts;
    },
};

export const actions = {
    changeStatements({commit}, {stmts}) {
        commit('CHANGE_STMT', stmts);
    },
};

export const getters = {
    getStatements: (state) => state.statements,
};