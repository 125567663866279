/**
 * Routers
 *
 */
const _default_ = [
    {
        path: "/",
        redirect: '/advance/igt',
    },
    {
        path: "/advance",
        redirect: '/advance/igt',
    },
];

const advance = [
    {
        path: "/advance/igt",
        name: "advance",
        component: () => import("@/views/oms/advance/index.vue"),
        meta: {
            title: '预售',
        },
    },
];

export default [
    ..._default_,
    ...advance,
];