<template>
  <router-view></router-view>

</template>

<script>

export default {
  name: 'App',
  components: {},
  watch: {
  },
  methods: {
  },
  created() {
    /**
     * -------------------------------------
     * In order to solve the problem that when using element-plus component,
     * it happens that the window Y-axis scrolling axis triggers a change at the same time as the elements in the page,
     * and the change is triggered by the data of the bound component,
     * which in turn causes the [ResizeObserver loop completed with undelivered notifications] error.
     * component [el-select、el-table] triggers this error.
     * @Evt.Lee
     * @link https://developer.mozilla.org/zh-CN/docs/Web/API/Resize_Observer_API
     * -------------------------------------
     * This code defines a function called 'debounce' and then overrides the 'ResizeObserver' property on the global object 'window'
     * The purpose of the 'debounce' function is to create a new function that will wait for a specific delay time before executing.
     * In this code, it is used to reduce the frequency of execution of the 'ResizeObserver' callback function.
     * @param callback
     * @param delay
     * @returns {(function(): void)|*}
     */
    const debounce = (callback, delay) => {
      let tid;
      return function (...args) {
        const context = this;
        clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(context, args);
        }, delay);
      }
    };
    /**
     * The constructor of the new class accepts a callback function as an argument.
     * In this constructor, the callback function is assigned as a 'debounce' function with a delay of 100 milliseconds.
     * The constructor of the parent class (i.e. '_ResizeObserver') is then called using this throttled callback function.
     * In this way, whenever a new instance of 'ResizeObserver' is used to observe the size of an element,
     * the execution of the callback function is limited to once every 100 milliseconds.
     * @type {ResizeObserver}
     * @private constructor
     */
    const _originalResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _originalResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 100);
        super(callback);
      }
    };
  }
}
</script>
