import {createWebHistory, createRouter} from "vue-router";
import routes from './routes';

const router = createRouter({
    mode: 'history',
    history: createWebHistory('/'),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0, left: 0};
        }
    },
});

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if(nearestWithTitle) {
        document.title = "ARBOR预售"
    }
    next();
});
export default router;
